import piletina from "../images/rec/piletina.webp";
import pasulj from "../images/rec/pasulj.webp";
import heljdopita from "../images/rec/heljdopita.webp";
import barena from "../images/rec/barena.webp";
import brokoli from "../images/rec/brokoli.webp";
import kajgana from "../images/rec/kajgana.webp";
import kuruz from "../images/rec/kuruz.webp";
import leblebija from "../images/rec/leblebija.webp";
import malina from "../images/rec/malina.webp";
import miks from "../images/rec/miks.webp";
import musaka from "../images/rec/musaka.webp";
import musli from "../images/rec/musli.webp";
import paprika from "../images/rec/paprika.webp";
import rukola from "../images/rec/rukola.webp";
import salatajaja from "../images/rec/salatajaja.webp";
import sataras from "../images/rec/sataras.webp";
import testenina from "../images/rec/testenina.webp";
import tuna from "../images/rec/tuna.webp";
import tikvica from "../images/rec/tikvica.webp";

export const recepti = [
  {
    title: "Barena piletina sa pečenim semenkama suncokreta",
    ing: ["piletina", "seme suncokreta", "paradajz", "krastavac"],
    text: " Piletini otkloniti kožicu, masne žilice, pa je iseckati na kockice i obariti. Seme suncokreta staviti u pleh i peći ih na par minuta u zagrejanoj rerni. Paradajz i krastavc oprati i iseckati na manje komade. Kada je sve gotovo, u tanjir staviti barenu piletinu, pečen susam, kao dodatak staviti paradajz i krastavac.",
    photo: piletina,
  },
  {
    title: "Bareni crveni pasulj sa salatom",
    ing: ["crveni pasulj", "paradajz", "zelena salata", "masline"],
    text: "Paradajz i zelenu salatu oprati i usitniti po želji . U odgovarajuću posudu staviti bareni crveni pasulj, dodati masline I pripremljen paradajz i zelenu salatu. Salatu poprskati limunovim sokom i maslinovim uljem.",
    photo: pasulj,
  },
  {
    title: "Heljdina pita",
    ing: ["kora od heljde", "jaje", "sir"],
    text: "Umutiti jaje, dodati izgnječen sir, malo posoliti i premazati koru. Premazanu koru saviti, i staviti u pleh za mafine i peći.",
    photo: heljdopita,
  },
  {
    title: "Barena piletina",
    ing: ["piletina", "kiseli krastavčići", "crni luk", "masline zelene"],
    text: "Piletinu očistiti od kožice i masnih žilica, oprati i iseckati na kockice. Kada se stavi u tanjir, dodati iseckane kisele krastavčići, crni luk i masline.",
    photo: barena,
  },
  {
    title: "Grilovan brokoli sa piletinom",
    ing: ["brokoli", "piletina", "krastavac", "paradajz"],
    text: "Brokoli očistiti, oprati. Piletini otkloiti kožicu, masne žilice i iseći je na manje komade. U grilovan tiganj prvo izgrilovati piletinu, a pri kraju dodati brokoli. Za salatu: očistiti, oprati krastavc i paradajz, a zatim usitniti na željene komade. Salatu se može preliti sa maslinovim uljem.",
    photo: brokoli,
  },
  {
    title: "Integralne testenine sa mladim sirom",
    ing: ["integralna testenina", "mlad sir", "paradajz"],
    text: "U odgovarajuću posudu sipati vodu i obariti integralnu testeninu po upustvu sa pakovanja. Mladi sir iseckati na kocke. Paradajz oprati i usitniti. Kada se ohladi testenina, staviti u činiju, dodati mlad sir, pardajaz.",
    photo: testenina,
  },
  {
    title: "Kuvana leblebija",
    ing: ["leblebija", "šargarepa", "pardajz"],
    text: "Leblebiju skuvati i servirati na tanjir sa rendanom šargarepom i paradajzom.",
    photo: leblebija,
  },
  {
    title: "Musaka od tikvica",
    ing: [
      "tikvica",
      "crni luk",
      "šargarepa",
      "juneće mleveno meso",
      "jaje",
      "paradajz",
    ],
    text: "Tikvicu 1 kom oljuštiti, oprati i iseckati na listove. Pripremiti fil: Izdinstati juneće mleveno meso 150g sa crnim lukom 1/2 kom i izrendanom šargarepom 1/2 kom. Kada je smesa gotova, malo posoliti i izmešati. Ređati sledećim redom: U odgovarajuću posudu poredjati tikvice u jednom redu, zatim fil od mesa pa paradajz 1 kom isečen na kolutove. Zatim umutiti 1 jaje i preliti musaku. Peći je u zagrejanoj rerni 30-35min na T=180-200 0 C. Kao dodatak može tost integralni i čeri paradajz.",
    photo: musaka,
  },
  {
    title: "Musli od ovsenih pahuljica i suvih smokvi",
    ing: ["ovsene pahuljice", "suve smokve", "jogurt"],
    text: "U činiju sipati ovsene pahuljice, jogurt i dodati iseckane suve smokve. Sve sastojke izmešati i ostaviti da odstoje 5 minuta.",
    photo: musli,
  },
  {
    title: "Pečena paprika sa pečurkama",
    ing: ["paprika", "pečurke", "mlad sir", "kukuruz šećerac"],
    text: "Oprati i očistiti papriku. Tako isto pripremiti pečurke. Papriku u topao tiganj peći i pri kraju njene termičke obrade, dodati pečurke, malo posoliti i nastaviti da se peče. Kada je gotovo, sipati u tanjir i dodati mlad sir, kukuruz šećerac.",
    photo: paprika,
  },
  {
    title: "Punjena tikvica sa mlevenim mesom",
    ing: ["tikvica", "juneće mleveno meso", "praziluk", "čeri paradajz"],
    text: "Dinstati juneće mleveno meso na vodi. Pri kraju malo posoliti. Tikvicu dobro oprati i seći je na svakih 2 centimetra razmaka ali seći tako da se ne preseče. U svakom razmaku sipati kašiku mlevenog mesa. U odgovarajuću posudu staviti papir za pečenje i pripremljenu tikvicu. Staviti u zagrejanu rernu i peći 20-25minuta na t=180-200 0 C. Uz punjenu tikvicu možete pripremiti salatu od praziluka i čeri paradajza ili neku drugu željenu salatu.",
    photo: tikvica,
  },
  {
    title: "Salata od kajgane i zelene salate",
    ing: ["jaja", "zelena salata", "dvopek", "kukuruz šećerac"],
    text: "Pripremiti kajganu od jaja i iseckati je na kocke. Zelenu salatu oprati i iseckati na rezance i staviti u činiju. Dodati iseckanu kajganu, iseckan dvopek i kukuruz šećerac. Poprskati za par kapi limuna i preliti sa maslinovo im uljem.",
    photo: kajgana,
  },
  {
    title: "Salata od kukuruza šećerca i rukole",
    ing: ["kukuruz šećerac", "rukola", "krastavac", "masline zelene"],
    text: "Svo nadvedeno povrće očistit, oprati. Kukuruz šećerac (ako je konzerviran - potrebno je samo izmeriti određenu količinu; ako je zamrznut – potrebno ga je blanširati). U posudu staviti rukolu, kukurz šećerac, iseckan krastavac, masline zelene. Salata se može poprskati sa limunovim sokom i preliti sa par kapi maslinovog ulja.",
    photo: kuruz,
  },
  {
    title: "Sataraš",
    ing: ["paprika", "paradajz", "crni luk", "dvopek"],
    text: "Povrće očistiti i usitniti. Izdinstati crni luk da dobije staklast izgled, a zatim dodati paprika. Pri kraju pečenja dodati paradajz i još 10tak minuta peći. Kada je gotovo sipati u tanjir i kao dodatak – dvopek.",
    photo: sataras,
  },
  {
    title: "Salata sa kuvanim jajima",
    ing: [
      "jaje",
      "rukola",
      "krastavac",
      "kruška",
      "crni hleb",
      "masline crne",
      "limunov sok",
    ],
    text: "Jaje skuvati i ostaviti da se ohladi. U međuvremenu, sve ostale namirnice oprati, očistiti i iseckati na kocke željene veličine. U odgovajuću posudu, staviti rukolu, iseckan krastavac, jaje, masline, krušku, hleb. Poprskati sa limunovim sokom.",
    photo: salatajaja,
  },
  {
    title: "Tuna namaz",
    ing: [
      "tunjevina",
      "beli luk",
      "senf",
      "peršun list",
      "crni luk",
      "čeri paradajz",
      "tost integralni",
    ],
    text: "Tunjevinu, beli luk, senf, peršun list sjediniti u šolju i izmiksirati štapnim mikserom ili izblendirati. Namaz namazati na tost integralni, a kao salatu koristiti crni luk i čeri paradajz.",
    photo: tuna,
  },
  {
    title: "Smuti od maline",
    ing: ["maline", "jogurt", "mleveni lan"],
    text: "Sve navedene namirnice staviti u blender i blendirati. Kada je gotovo sipati u čašu i dekorisati po želji.",
    photo: malina,
  },
  {
    title: "Salata od rukole i mladog sira",
    ing: ["rukola", "mlad sir", "rotkvice", "dvopek", "krastavac"],
    text: "Svo povrće očistiti, oprati, usitniti po želji i staviti u činiju. Dodati mladi sir iseckan na kockice i izlomljen dvopek. Salatu poprskati sa limunovim sokom.",
    photo: rukola,
  },
  {
    title: "Voćni miks sa bananom i suvim smokvama",
    ing: ["banana", "suve smokve", "Balans jogurt"],
    text: "Pripremljeno voće očistiti i iseckati na željene komade. Staviti u činiju i preliti sa Balans jogurtom.",
    photo: miks,
  },
];
