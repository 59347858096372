import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Layout from "../components/Layout";
import photo from "../images/recepti.webp";
import HeaderPhoto from "../components/HeaderPhoto";
import * as styles from "../styles/recepti.module.css";
import { recepti } from "../utils/recepti";
import RecipeModal from "../components/RecipeModal";





export default function Recepti() {
  const [open, setOpen] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  const openRecipe = (item) => {
    setSelectedRecipe(item);
    setOpen(true);
  }

  return (
    <Layout>
      <HeaderPhoto title="Recepti" photo={photo} />
      <div className={styles.recepti}>
        {recepti.map((item) => (
          <Card key={item.title} className={styles.recept} onClick={() => openRecipe(item)}>
            <CardMedia component="img" height="140" image={item.photo} />
            <CardContent className={styles.content}>
              <div className={styles.title}>
                {item.title}
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
      {open && (
        <RecipeModal
          open={open}
          handleClose={() => setOpen(false)}
          recipe={selectedRecipe}
        />
      )}
    </Layout>
  );
}

export const Head = () => <title>Sonja Nikacevic | Nutricionista</title>;
