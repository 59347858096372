import React from "react";
import { Dialog, Typography, List, ListItem } from "@mui/material";

function RecipeModal({ open, handleClose, recipe }) {
  const { title, ing, text, photo } = recipe;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"}>
      <div className="recipeCon">
        <img src={photo} alt={title} className="recipeImg" />
        <div className="recipeInfo">
          <div className="recipeTitle">{title}</div>
          <div>Sastojci:</div>
          <List sx={{ my: 2 }}>
            {ing.map((ingredient, index) => (
              <ListItem key={index}>{ingredient}</ListItem>
            ))}
          </List>
          <Typography id="recipe-modal-description" sx={{ mb: 2 }}>
            {text}
          </Typography>
          <div className="closeX" onClick={handleClose}>
            X
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default RecipeModal;
